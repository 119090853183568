import React, { useCallback, useEffect, useState, useRef } from 'react';

import CancelIcon from '@material-ui/icons/Cancel';
import CircularProgress from '@material-ui/core/CircularProgress';
import SaveIcon from '@material-ui/icons/Save';
import * as apiClient from "../../common/apiClient";
import { useStyles } from "../../formStyles";
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import 'ag-grid-community/styles/ag-theme-balham.css';
import { toast } from 'react-toastify';
import FileUpload from './FileUpload';
import { Button } from '@material-ui/core';


const InvoiceUploadDetails = props => {
    const classes = useStyles({});
    const invoiceId = props.invoiceId
    const [csvData, setCsvData] = useState([]);
    const [loading, setLoading] = useState<any>(false);
    const [result, setResult] = useState<any>(null);

    const importClaimInvoices = () => {
        let user: any = localStorage.getItem("userId");
        if (user) {
            user = parseInt(user);
        }
        let data = {
            userId: user,
            data: csvData

        }
        setLoading(true);
        setResult(null);
        apiClient.importClaimInvoices(data).then(res => {
            if (res.data) {
                setResult(JSON.stringify(res.data));
            }
            setLoading(false);
        }).catch(err => {
            toast.error(err);
            setLoading(false);
        });
    }

    const handleFileUpload = (data) => {
        setCsvData(data);
    };

    return (

        <div style={{ padding: 10, paddingBottom: 100 }} >
            <h4>Upload Invoices</h4>
            <br />
            <FileUpload onFileUpload={handleFileUpload} />
            {result && <div>{result}</div>}
            {loading && <CircularProgress />}
            <div style={{ display: "flex", flexDirection: "row", marginBottom: 20, marginTop: 20 }} >
                {csvData.length > 0 && <div style={{ marginRight: "10px" }}>
                    <Button variant="contained" color="primary" onClick={importClaimInvoices}
                        startIcon={<SaveIcon />}
                    >Import</Button>
                </div>
                }
                <div>
                    <Button
                        variant="contained" color="primary"
                        onClick={props.onClose}
                        startIcon={<CancelIcon />}
                    >Back</Button>
                </div>

            </div>
        </div>
    );
}
export default InvoiceUploadDetails;