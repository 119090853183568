import React, { useState, useEffect } from "react";
import { Admin, Resource } from "react-admin";
import { ListGuesser, EditGuesser } from "react-admin";
import { APIUrl } from "./common/apiClient";
import { MyDataProvider } from "./providers/MyDataProvider";
import VSDataProvider from "./providers/VSDataProvider";

import polyglotI18nProvider from "ra-i18n-polyglot";

import "./App.css";

import authProvider from "./authProvider";
import themeReducer from "./themeReducer";
import { Login, Layout } from "./layout";
import { MainHome } from "./layout/Home";
import { Dashboard } from "./dashboard";
import customRoutes from "./routes";
import englishMessages from "./i18n/en";
import employeeattendancereqlist from "./screens/employeeattendancereqlist";
import employeeattendancereqapprove from "./screens/employeeattendancereqapprove";
import policymailconfig from './screens/policy-mail-config';
import policymaillog from './screens/policy-mail-log';
import roletypes from './screens/roletypes';
import district from "./screens/district";
import state from "./screens/state";
import city from "./screens/city";
import countries from "./screens/country";
import areas from './screens/areas';
import users from './screens/users';
import employee from './screens/employee';
import tpa from './screens/tpa';
import insurer from './screens/insurer';
import corporate from './screens/corporate';
import vendor from './screens/vendor';
import hospital from './screens/hospital';
import realizeorder from './screens/realize-order';
import hosprealizeorder from './screens/hosp-realize-order';
import hospitalinvoice from './screens/hospitalinvoice';
import claiminvoice from './screens/claiminvoice';

import leavetype from './screens/leavetype';
import holidaylist from './screens/holidaylist';
import employeeleavelist from './screens/employeeleavelist'; 
import employeeleaveapprove from './screens/employeeleaveapprove'; 
import employeesalary from './screens/employeesalary'; 
import dsr from './screens/dsr';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import configs from './screens/configs';
import claimorder from "./screens/claim-order";
import fopayments from './screens/fo-payments';
import lookup from "./screens/lookup";
import zipcode from "./screens/zipcode";




const i18nProvider = polyglotI18nProvider((locale) => {
  if (locale === "fr") {
    return import("./i18n/fr").then((messages) => messages.default);
  }

  // Always fallback on english
  return englishMessages;
}, "en");
//console.log(process.env.REACT_APP_SERVER_URL, "==REACT_APP_SERVER_URL==");
const App = () => {
  const [dataProvider, setDataProvider] = useState({});

  useEffect(() => {
    let provider = VSDataProvider(APIUrl);
    setDataProvider(provider);

  }, []);

  return (
    <>
      <Admin
        title=""
        dataProvider={dataProvider}
        customReducers={{ theme: themeReducer }}
        customRoutes={customRoutes}
        authProvider={authProvider}
        dashboard={Dashboard}
        loginPage={Login}
        layout={Layout}
        i18nProvider={i18nProvider}
      >
        <Resource title="Area" name="areas" {...areas} />
        <Resource title="User" name="users" {...users} />
        <Resource title="Employee" name="employees" {...employee} />
        <Resource title="TPA" name="tpas" {...tpa} />
        <Resource title="Insurer" name="insurers" {...insurer} />
        <Resource title="Corporate" name="corporates" {...corporate} />
        <Resource title="vendor" name="vendors" {...vendor} />
        <Resource title="Hospital" name="hospitals" {...hospital} />
        <Resource title="Reliaze order" name="realiz-ords"   {...realizeorder} />
        {/* <Resource name="lookups" list={ListGuesser} edit={EditGuesser} /> */}
        <Resource name="realiz-ord-dets" list={ListGuesser} edit={EditGuesser} />
        <Resource name="realiz-ord-docs" list={ListGuesser} edit={EditGuesser} />
        <Resource title="Role Type" name="role-types" {...roletypes} />
        <Resource title="Hospital" name="hospital-dets" {...roletypes} />
        <Resource name="hospital-docs" list={ListGuesser} edit={EditGuesser} />
        <Resource name="configdets" list={ListGuesser} edit={EditGuesser} />
        <Resource name="configs" {...configs} />
        <Resource name="policies-mail-configs" {...policymailconfig} />
        <Resource name="policies-mail-logs" {...policymaillog}  /> 
        <Resource name="dsrs" {...dsr} />
        <Resource name="hospital-ins-dets" list={ListGuesser} edit={EditGuesser}  />
        <Resource name="hospital-real-dets" list={ListGuesser} edit={EditGuesser}  />
        <Resource name="hospital-realiz-ords" {...hosprealizeorder}  />
        <Resource name="hospital-realiz-ords" {...hosprealizeorder}  />
        {/* <Resource name="hospital-invoice" {...hospitalinvoice}/> */}
        <Resource name="claim-invs" {...claiminvoice}/>
        
        <Resource name="leave-types" {...leavetype}/>
        <Resource name="holiday-lists" {...holidaylist}/>
        <Resource name="employee-leaves" {...employeeleavelist}/>
        <Resource name="employee-leaves-approve"  {...employeeleaveapprove}/>
        <Resource name="employee-sal"  {...employeesalary}/>
        <Resource name="employee-sal-dets"  list={ListGuesser} edit={EditGuesser}  />
        <Resource name="claim-ords" {...claimorder} />
        <Resource name="claim-ord-docs" list={ListGuesser} edit={EditGuesser} />
        <Resource name="claim-ord-post-docs" list={ListGuesser} edit={EditGuesser} />
        <Resource name="claim-ord-asks" list={ListGuesser} edit={EditGuesser} />
        <Resource name="claim-ord-invs" list={ListGuesser} edit={EditGuesser} />
        <Resource name="claim-ord-allots" list={ListGuesser} edit={EditGuesser} />
        <Resource name="insurer-inv-dets" list={ListGuesser} edit={EditGuesser} />
        <Resource name="insurer-circular-docs" list={ListGuesser} edit={EditGuesser} />
        <Resource name="tpa-circular-docs" list={ListGuesser} edit={EditGuesser} />
        <Resource name="tpa-claim-inv-dets" list={ListGuesser} edit={EditGuesser} />  
        <Resource title="Country" name="countries" {...countries} />
        <Resource title="City" name="cities" {...city} />
        <Resource title="States" name="states" {...state} />
        <Resource name="districts" {...district} />
        <Resource name="fo-payments" {...fopayments} />
        <Resource name="fo-payment-dets" list={ListGuesser} edit={EditGuesser} />  
        <Resource name="lookups" {...lookup} />
        <Resource name="employee-attendance-regularizes" {...employeeattendancereqlist}/>
        <Resource name="employee-attendance-regularizes-approve" {...employeeattendancereqapprove}/>
        <Resource name="zipcodes" {...zipcode} />

        
      </Admin>
      <ToastContainer />
    </>
  );
};

export default App;

