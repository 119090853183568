import React, { useState, useEffect,useRef  } from "react";
import {
  Edit,
  FormDataConsumer,
  SelectInput,
  TextInput,
  BooleanInput,
  required,
  ReferenceInput,
  NumberInput,
  ImageField,

  SimpleForm,
  FormTab,
  useNotify,
  AutocompleteInput,
  DateInput,
  useRedirect
} from "react-admin";
import { useStyles } from "../../formStyles";
import { CreateToolbar } from "../../CreateToolbar";
import moment from "moment";
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TextareaAutosize from '@material-ui/core/TextareaAutosize';
import * as apiClient from "../../common/apiClient";
import { Button } from "@material-ui/core";
import Print from '@material-ui/icons/Print';
import { makeStyles } from '@material-ui/core/styles';
import { Drawer } from '@material-ui/core';
import InvoicePrint from "./InvoicePrint/InvoicePrint";
import InvoicePrint2 from "./InvoicePrint2/InvoicePrint";
import exportFromJSON from 'export-from-json'

const myStyles = makeStyles(theme => ({
  root: {
      display: 'flex',
  },
  list: {
      flexGrow: 1,
      transition: theme.transitions.create(['all'], {
          duration: theme.transitions.duration.enteringScreen,
      }),
      marginRight: 0,
  },
  listWithDrawer: {
      marginRight: 300,
  },
  drawerPaper: {
      zIndex: 100,
      marginTop: 50,
      marginRight: 5,
      
      width: 650,
  },
}));
export const InvoiceEdit = (props) => {
  const notify = useNotify();
  const redirect = useRedirect();
  const classes = useStyles({});
  const [firstLoad, setfirstLoad] = useState(true)
  const [invoiceData, setInvoiceData] = useState([])
  const [toTaxPer, setToTaxPer] = useState(0)
  const [isTpaInvoice, setIsTpaInvoice] = useState(false)
  const [toId, setToId] = useState(0)
  const [claimOrdId, setClaimOrdId] = useState(0)
  const [toTaxTypeId, setTaxTypeId] = useState(0)
  const [invoiceId, setInvoiceId] = useState(0)
  const [invoiceAmount, setinvoiceAmount] = useState(0)
  const [invoiceTaxAmount, setInvoiceTaxAmount] = useState(0)
  const [invoiceRoundOfAmount, setInvoiceRoundOfAmount] = useState(0)
  const [invoiceGrandTotal, setInvoiceGrandTotal] = useState(0)
  const [toTypeId, setToTypeId] = useState(0)
  const [invTypeData, setinvTypeData] = useState([{ id: '43', name: 'CLAIM' }, { id: '44', name: 'RECLAIM' }, { id: '45', name: 'Tie-Up' }, { id: '46', name: 'Desk Management' }, { id: '47', name: 'FreeHand' }, { id: '50', name: 'Remarks NP' }])
  const [isPrint, setIsPrint] = useState(false);
  const [isPrintWithOutHeader, setIsPrintWithOutHeader] = useState(false);
  const [claimInvDetId, setClaimInvDetId] = useState(0)
  const [data, setData] = useState([])
  const [invSubDate, setInvSubDate] = useState(new Date())
  
  
  const myclasses = myStyles({});

  const loadinvoiceData = (invoiceId) => {
    apiClient.getInvoiceData(invoiceId).then(res => {
      let data = [...res]
      setInvoiceData([...data])
    }).catch(err => {
      alert(err)
      console.log(err);
    });
  }
  const closeEdit = () => {
    setIsPrint(false)
    setIsPrintWithOutHeader(false);
  }
  function exportToCSV(data) {
    const fileName = 'download'  
  const exportType = 'xls'
exportFromJSON({ data, fileName, exportType })  
    
}
  const loadInvTypeCalculation = (claimOrdId, toId, invTypeId, fromdate, todate, index) => {

    let data = [...invoiceData]
    data[index].totalServiceAmount = 0;
    data[index].totalServicePerc = 0;
    data[index].totalAmount = 0;
    data[index].particulars = "";
    setInvoiceData([...data])
    let typeId = 0;
    if (isTpaInvoice) {
      typeId = 1;
    }
    apiClient.getCalimOrd_BillingPerc_ByDateRange(claimOrdId, toId, typeId, "2012-01-01", "2012-01-01").then(res => {
      if (res.length === 0) { notify("No claim found", "warning") }
      else {
        let data = [...invoiceData]
        setInvoiceData([...data])
        calulateTotal([...data]);
      }


    }).catch(err => {
      alert(err)
      console.log(err);
    });
  }
  const handleRemove = async (index) => {
    let data = [...invoiceData]
    data.splice(index, 1);
    setInvoiceData([...data])
    calulateTotal([...data])
  }

  const handleAdd = async (index) => {
    let data = [...invoiceData]
    data.push({ toId: toId, claimOrdInvId: 1, invTypeId: 0, claimOrdStartDate: "", claimOrdEndDate: "", particulars: "", totalAmount: 0, totalServicePerc: 0, totalServiceAmount: 0 })
    setInvoiceData([...data])
  }

  // const handleChange= async(event,index,identifier)=>
  // {
  //   if(identifier=="hospital")
  //   {
  //     alert(event.target.value);
  //     return
  //   }
  //   let data=[...invoiceData]
  //   data[index][identifier]=event.target.value;
  //   if(identifier==='invTypeId')
  //   {
  //     data[index]["totalAmount"]=0;
  //     data[index]["claimOrdStartDate"]="";
  //     data[index]["claimOrdEndDate"]="";
  //   }
  //   setInvoiceData([...data])
  //   if((identifier==='claimOrdStartDate') || (identifier==='claimOrdEndDate'))
  //   {
  //     let payload={}
  //     payload.fromdate=data[index].claimOrdStartDate
  //     payload.todate=data[index].claimOrdEndDate
  //     if(identifier==='claimOrdStartDate')
  //     {
  //       payload.fromdate=event.target.value
  //     }
  //     if(identifier==='claimOrdEndDate')
  //     {
  //       payload.todate=event.target.value
  //     }

  //     let filterinvTypeData= invTypeData.filter(x=>x.id===data[index].invTypeId);
  //     if(filterinvTypeData.length && (filterinvTypeData[0].name==='CLAIM' || filterinvTypeData[0].name==='RECLAIM' ))
  //     {

  //       payload.invTypeId=filterinvTypeData[0].name;
  //       if(payload.fromdate.length &&  payload.todate.length )
  //       loadInvTypeCalculation(toId,payload.invTypeId,payload.fromdate,payload.todate,index) ;
  //     }


  //   }
  //   calulateTotal([...data]);

  // }
  const handleChange = async (event, index, identifier) => {
    if (identifier == "hospital") {
      alert(event.target.value);
      return
    }
    let data = [...invoiceData]
    data[index][identifier] = event.target.value;
    if (identifier === 'invTypeId') {
      data[index]["totalAmount"] = 0;
      data[index]["claimOrdStartDate"] = "";
      data[index]["claimOrdEndDate"] = "";
    }
    setInvoiceData([...data])
    if ((identifier === 'invTypeId')) {
      let payload = {}
      payload.fromdate = data[index].claimOrdStartDate
      payload.todate = data[index].claimOrdEndDate
      if (identifier === 'claimOrdStartDate') {
        payload.fromdate = event.target.value
      }
      if (identifier === 'claimOrdEndDate') {
        payload.todate = event.target.value
      }

      let filterinvTypeData = invTypeData.filter(x => x.id === data[index].invTypeId);
      debugger
      if (filterinvTypeData.length && (filterinvTypeData[0].name === 'CLAIM' || filterinvTypeData[0].name === 'RECLAIM')) {

        payload.invTypeId = filterinvTypeData[0].name;
        //if(payload.fromdate.length &&  payload.todate.length )
        loadInvTypeCalculation(claimOrdId, toId, payload.invTypeId, payload.fromdate, payload.todate, index);
      }


    }
    calulateTotal([...data]);

  }
  const handleSubmit = async (formdata) => {
    debugger
    console.log(formdata);
    let user = localStorage.getItem("userId");
    if (user) {
      user = parseInt(user);
    }
    if (formdata.billYearFrom > formdata.billYearTo) {
      notify("Please Valid Bill Year From", "warning")
      return;
    }
    let IsValid = true;
    let errormessage = "";
    debugger
    for (let index = 0; index < invoiceData.length; index++) {
      if (invoiceData[index].invTypeId <= 0) {
        errormessage = `Please Select Invoice Type at row no ${index + 1}`
        IsValid = false;
        break
      }
      // if(invoiceData[index].realStartDate==="" && invoiceData[index].invTypeId!=50)
      // {
      //   errormessage=`Please Select Date From at row no ${index+1}`
      //   IsValid=false;
      //   break
      // }
      // if(invoiceData[index].realEndDate==="" && invoiceData[index].invTypeId!=50)
      // {
      //   errormessage=`Please Select Date To at row no ${index+1}`
      //   IsValid=false;
      //   break
      // }
      if (invoiceData[index].particulars === "") {
        errormessage = `Please Enter Particulars at row no ${index + 1}`
        IsValid = false;
        break
      }
      debugger
      if (invoiceData[index].invTypeId != '47' || invoiceData[index].invTypeId != 47) {
        if (invoiceData[index].totalAmount < 0) {
          errormessage = `Please Enter Amount  at row no ${index + 1}`
          IsValid = false;
          break
        }
      }

    }
    if (!IsValid) {
      notify(errormessage, "warning")
      return;
    }
    let data = {};
    data.invoiceId = invoiceId;
    data.toId = toId;
    data.claimOrdId = claimOrdId;
    data.taxtypeId = toTaxTypeId;
    data.totypeId = isTpaInvoice === true ? 1 : 0;
    data.taxPer = toTaxPer;
    data.billYearFrom = formdata.billYearFrom;
    data.billYearTo = formdata.billYearTo;
    data.billMonth = formdata.billMonthId;
    data.invoiceDate = formdata.invoiceDate;
    data.invoiceAmount = invoiceAmount;
    data.taxAmount = invoiceTaxAmount;
    data.roundOffAmount = invoiceRoundOfAmount;
    data.totalAmount = invoiceGrandTotal;
    data.userId = user;
    data.invoiceDetail = [...invoiceData];
    data.paymentrcvd = 0;
    data.paymentrcvddt = "";

    data.isInvoiceSubmitted = 0;
    data.invoiceSubDate = "";


    data.NeftDetails = "";
    data.isInvoiceSubmitted=0;
    data.invoiceSubDate="";
    data.fromDate = formdata.fromDate;
    data.toDate = formdata.toDate;
    if (formdata.PaymentRcvd === null || formdata.PaymentRcvd === undefined) {
      formdata.PaymentRcvd = 0;
    }
    if (formdata.PaymentRcvd === true) {
      formdata.PaymentRcvd = 1;
    }
    else {
      formdata.PaymentRcvd = 0;
    }



    if (formdata.isInvoiceSubmitted === null || formdata.isInvoiceSubmitted === undefined) {
      data.isInvoiceSubmitted = 0;
    }
    if (formdata.isInvoiceSubmitted === true) {
      data.isInvoiceSubmitted = 1;
    }
    else {
      data.isInvoiceSubmitted = 0;
    }


    data.paymentrcvd = formdata.PaymentRcvd;
    data.invoiceSubDate=formdata.invoiceSubDate;
    if (formdata.invoiceSubDate === null || formdata.invoiceSubDate === undefined) {
      data.invoiceSubDate = ""
    }
    if (formdata.neftDetails === null || formdata.neftDetails === undefined) {
      formdata.neftDetails = ""
    }
    data.paymentrcvddt = formdata.paymentRcvdDt;
    data.invoiceSubDate = formdata.invoiceSubDate; 
    data.NeftDetails = formdata.neftDetails;

    apiClient.addupdateClaimInvoice(data).then(res => {
      if (res[0].result) {
        notify(res[0].message)
        redirect("/claim-invs");
      }
      else {
        notify(res[0].message)
      }
    }).catch(err => {
      notify("unable to add Invoice", "warning")
      console.log(err);
    });
  }
  const calulateTotal = async (invData) => {

    let data = [...invData]
    let _invData = [];
    let invoiceAmount = 0
    let finalinvoiceAmount = 0
    let invoiceTaxAmount = 0
    let invoiceRoundOff = 0
    let invoiceFinalAmount = 0
    data.forEach((Element) => {
      invoiceAmount = parseFloat(invoiceAmount) + parseFloat(Element.TotalServiceAmount) + parseFloat(Element.TatIncentiveAmount) + parseFloat(Element.DocumentAmount) + parseFloat(Element.ExtraConveyance)
      finalinvoiceAmount = 0;
      finalinvoiceAmount = parseFloat(Element.TotalServiceAmount) + parseFloat(Element.TatIncentiveAmount) + parseFloat(Element.DocumentAmount) + parseFloat(Element.ExtraConveyance)
      Element.FinalAmount = finalinvoiceAmount.toFixed(2);
      _invData.push(Element);
    })
    invoiceTaxAmount = (invoiceAmount * toTaxPer * .01)
    invoiceRoundOff = Math.round(invoiceAmount + invoiceTaxAmount) - (invoiceAmount + invoiceTaxAmount);

    invoiceFinalAmount = parseFloat(invoiceAmount) + parseFloat(invoiceTaxAmount) + parseFloat(invoiceRoundOff);
    setinvoiceAmount(invoiceAmount.toFixed(2));
    setInvoiceTaxAmount(invoiceTaxAmount.toFixed(2));
    setInvoiceRoundOfAmount(invoiceRoundOff.toFixed(2))
    setInvoiceGrandTotal(invoiceFinalAmount.toFixed(2));
    setInvoiceData(_invData);

  }
  const getOrderdetail = async () => {
    let payload= {"fromDate":"2023-11-01","toDate":"2023-11-24","pName":"","hospitalID":0,"tpaId":0,"corporateID":0,"insurerID":0,"rptName":"HIR",calimInvID:invoiceId};
    apiClient.loadReport("/report/masterreport",payload).then(res => {
      console.log(res);
      exportToCSV(res);
       
   
     
      

  }).catch(err => {
      console.log(err);
      
  });

  }
  const loadtodata = (id) => {

    if (id === undefined) {
      return;
    }
    let istpa = 0;
    if (isTpaInvoice) {
      istpa = 1;
    }
    setToTaxPer(0)
    setTaxTypeId(0)
    apiClient.get_tpa_insurer_data(id, istpa).then(res => {
      if (res.length) {
        setToTaxPer(res[0].taxPercentage)
        setTaxTypeId(res[0].TaxtTypeID)
      }
    }).catch(err => {
      console.log(err);
    });
  }
  return (
    <>
    <Edit title=" Invoice Edit " {...props}>
      <SimpleForm variant="outlined" redirect="list" toolbar={<CreateToolbar {...props} onSave={handleSubmit} />} onSubmit={handleSubmit} >
        
        {!firstLoad && isTpaInvoice && <BooleanInput source="isTpa" label="Is TPA Invoice" initialValue={true}  disabled />}
        
        {!firstLoad && !isTpaInvoice && <BooleanInput source="isTpa" label="Is TPA Invoice"
         
         initialValue={false}
         onChange={(val) => setIsTpaInvoice(val)} disabled 
          />}
        {isTpaInvoice && <ReferenceInput perPage={250} sort={{ field: 'name', order: 'ASC' }}
          filterToQuery={searchText => ({ 'name~like': searchText })}
          label="TPA Name" source="toId" reference="tpas" fullWidth={true} formClassName={classes.one_4_input} inititalValue={toId}
          onChange={(event) => { setToId(event); loadtodata(event) }}
        >
          <SelectInput optionText="name" disabled value={toId} />
        </ReferenceInput>
        }
        {!isTpaInvoice && <ReferenceInput perPage={250} sort={{ field: 'name', order: 'ASC' }}
          filterToQuery={searchText => ({ 'name~like': searchText })}
          label="Insurer Name" source="event" reference="insurers" fullWidth={true} formClassName={classes.one_4_input} inititalValue={toId}
          onChange={(event) => { setToId(event); loadtodata(event) }}
        >
          <SelectInput optionText="name" disabled value={toId} />
        </ReferenceInput>
        }

        <ReferenceInput label="Tax Type" perPage={5000} source="taxtTypeID" fullWidth={true} filter={{ type: 'TT' }} reference="lookups" formClassName={classes.two_4_input} disabled  >
          <SelectInput optionText="name" disabled value={toTaxTypeId} />
        </ReferenceInput>

        <NumberInput label="Tax Percentage" source="taxPercentage" fullWidth={true} formClassName={classes.three_4_input} disabled value={toTaxPer} />
        <SelectInput source="billYearFrom" label="Bill Year Form" validate={[required()]} fullWidth={true} formClassName={classes.last_4_input} choices={[{ id: '2020', name: '2020' }, { id: '2021', name: '2021' }, { id: '2022', name: '2022' }, { id: '2023', name: '2023' }, { id: '2024', name: '2024' }, { id: '2025', name: '2025' }, { id: '2026', name: '2026' }, { id: '2027', name: '2027' }, { id: '2028', name: '2028' }, { id: '2029', name: '2029' }, { id: '2030', name: '2030' }]} disabled />
        <SelectInput source="billYearTo" label="Bill Year To" validate={[required()]} fullWidth={true} formClassName={classes.one_4_input} choices={[{ id: '2020', name: '2020' }, { id: '2021', name: '2021' }, { id: '2022', name: '2022' }, { id: '2023', name: '2023' }, { id: '2024', name: '2024' }, { id: '2025', name: '2025' }, { id: '2026', name: '2026' }, { id: '2027', name: '2027' }, { id: '2028', name: '2028' }, { id: '2029', name: '2029' }, { id: '2030', name: '2030' }]} disabled />

        <SelectInput source="billMonthId" label="Bill Month" validate={[required()]} fullWidth={true} formClassName={classes.two_4_input} choices={[{ id: '1', name: 'Jan' }, { id: '2', name: 'Feb' }, { id: '3', name: 'March' }, { id: '4', name: 'April' }, { id: '5', name: 'May' }, { id: '6', name: 'June' }, { id: '7', name: 'July' }, { id: '8', name: 'August' }, { id: '9', name: 'Sept' }, { id: '10', name: 'Oct' }, { id: '11', name: 'Nov' }, { id: '12', name: 'Dec' }]} disabled />
        <DateInput validate={[required()]} label="Invoice Date" source="invoiceDate" fullWidth={true} formClassName={classes.three_4_input} disabled />
        <div style={{ width: "40px" }} ></div>
        <DateInput validate={[required()]} label="From Date" source="fromDate" fullWidth={true} formClassName={classes.one_4_input} disabled />
        <DateInput validate={[required()]} label="To Date" source="toDate" fullWidth={true} formClassName={classes.two_4_input} disabled />

        <TextInput validate={[required()]} label="Invoice No" source="invoiceNo" fullWidth={true} formClassName={classes.three_4_input} disabled />
        <div   formClassName={classes.last_4_input}>
          <Button style={{marginTop:30}} onClick={()=>getOrderdetail()} variant="contained" color="primary">Download </Button>
        </div>
        <div style={{ width: 1100 }}>
          <BooleanInput variant="outlined" label="Invoice Submitted" source="isInvoiceSubmitted" fullWidth={false} className={classes.one_4_input} />
          
          <FormDataConsumer formClassName={classes.two_4_input}>
            {({ formData, ...rest }) =>
              formData.isInvoiceSubmitted != null &&
              formData.isInvoiceSubmitted === true && (

                <DateInput variant="outlined" label="Invoice Submitted Date" source="invoiceSubDate" fullWidth={false} className={classes.two_4_input} initialValue={invSubDate}  />
              ) 

            }
          </FormDataConsumer>


        </div>
        <div style={{ width: 1100 }}>
          <BooleanInput variant="outlined" label="Payment Received " source="PaymentRcvd" fullWidth={false} className={classes.one_4_input} />
          <FormDataConsumer formClassName={classes.two_4_input}>
            {({ formData, ...rest }) =>
              formData.PaymentRcvd != null &&
              formData.PaymentRcvd === true && (
                <DateInput variant="outlined" label="Payment Received Date" source="paymentRcvdDt" fullWidth={false} className={classes.two_4_input} />
              )

            }
          </FormDataConsumer>


        </div>
        <FormDataConsumer >
          {({ formData, ...rest }) =>
            formData.PaymentRcvd != null &&
            formData.PaymentRcvd === true && (
              <TextInput variant="outlined" label="Neft Details" source="neftDetails" fullWidth={true} />
            )
          }
        </FormDataConsumer>

        

        {
          toId > 0 && invoiceData.length > 0 &&
          <Table className={classes.customtable} size="small" aria-label="a dense table">
            <TableHead>
              <TableRow>
                {/* <TableCell >Invoice Type</TableCell> */}
                {/* <TableCell>Date From</TableCell>
                                <TableCell>Date To</TableCell> */}
                <TableCell>Print</TableCell>
                <TableCell>VID No</TableCell>
                <TableCell>Particulars</TableCell>
                <TableCell>Extra Conveyance</TableCell>
                <TableCell>Document Fee</TableCell>
                <TableCell>Incentive Fee</TableCell>
                <TableCell>Ded.Amt</TableCell>
                <TableCell>Prof Fee</TableCell>
                <TableCell>Total Amount</TableCell>
              </TableRow>
            </TableHead>
            <TableBody >
              {invoiceData.map((item, index) => {
                return <tr >
                  {/* <TableCell> 
                                        <select  style={{height:"35px",fontSize:"11px","border": "1px solid #C4C4C4","borderRadius": "5px"}} value={item.invTypeId}  onChange={(event)=>handleChange(event,index,"invTypeId")}   variant="outlined" source={"Col1"+index} label="Invoice Type"  fullWidth={true} formClassName={classes.one_4_input}  >
                                        <option   value={'0'}>Select </option>
                                        {invTypeData.map((item)=>{ 
                                          return <option  selected={item.id===item.invTypeId} value={item.id}>{item.name}</option>
                                        })} 
                                          </select>
                                      </TableCell> */}
                  {/* <TableCell>
                                      {(item.invTypeId!='50' ||  item.invTypeId!=50)? <input type="date"   style={{width:"122px",height:"35px",fontSize:"11px","border": "1px solid #C4C4C4","borderRadius": "5px"}}  value={item.claimOrdStartDate}  onChange={(event)=>handleChange(event,index,"claimOrdStartDate")} variant="outlined" validate={[required()]} label="Invoice Date" source={"Col2"+index} fullWidth={true} formClassName={classes.two_4_input} onKeyDown={(e) => e.preventDefault()} />:""}
                                      </TableCell>
                                      <TableCell>
                                      {(item.invTypeId!='50' ||  item.invTypeId!=50)?<input type="date"    style={{width:"122px",height:"35px",fontSize:"11px","border": "1px solid #C4C4C4","borderRadius": "5px"}}  value={item.claimOrdEndDate}  onChange={(event)=>handleChange(event,index,"claimOrdEndDate")} formClassName={classes.customInput} validate={[required()]} label="Invoice Date" source={"Col3"+index} fullWidth={true} formClassName={classes.two_4_input} onKeyDown={(e) => e.preventDefault()}/>:""}
                                      </TableCell> */}
                  <TableCell>
                    {item.ClaimInvDetId != undefined && item.ClaimInvDetId != null && <>
                      <div style={{display:"flex",flexDirection:"row",color:"blue"}} >
                        <div style={{marginRight:"10px",cursor:"pointer"}} onClick={()=>{setIsPrint(true);setClaimInvDetId(item.ClaimInvDetId)}}  >
                         <span> Print </span>
                        </div>
                        <div style={{marginRight:"10px",cursor:"pointer"}} onClick={()=>{setIsPrintWithOutHeader(true);setClaimInvDetId(item.ClaimInvDetId)}}>
                        <span> Print W/H </span>
                        </div>
                      </div>
                      
                      
                    </>
                    }

                  </TableCell>
                  <TableCell>
                    {item.ClaimNo}

                  </TableCell>
                  <TableCell>
                    {/* <TextareaAutosize variant="outlined" rowsMax={2} value={item.Particulars}  onChange={(event)=>handleChange(event,index,"Particulars")}    aria-label="maximum height" placeholder="Particulars" defaultValue=""  source={"Col4"+index}/> */}
                    <textarea style={{ height: "100px", fontSize: "11px", "border": "1px solid #C4C4C4", "borderRadius": "5px" }} value={item.Particulars} onChange={(event) => handleChange(event, index, "Particulars")} aria-label="maximum height" placeholder="Particulars" defaultValue="" source={"Col4" + index} />
                  </TableCell>
                  <TableCell>
                    <input style={{ width: "100px", textAlign: "right", height: "35px", fontSize: "11px", "border": "1px solid #C4C4C4", "borderRadius": "5px" }} type="number" variant="outlined" label="" source="TotalAmount" validate={[required()]} rowsMax={2} value={item.ExtraConveyance} onChange={(event) => handleChange(event, index, "ExtraConveyance")} source={"Col5" + index} disabled />
                  </TableCell>
                  <TableCell>
                    <input style={{ width: "100px", textAlign: "right", height: "35px", fontSize: "11px", "border": "1px solid #C4C4C4", "borderRadius": "5px" }} type="number" variant="outlined" label="" source="TotalAmount" validate={[required()]} rowsMax={2} value={item.DocumentAmount} onChange={(event) => handleChange(event, index, "DocumentAmount")} source={"Col5" + index} disabled />
                  </TableCell>
                  <TableCell>
                    <input style={{ width: "100px", textAlign: "right", height: "35px", fontSize: "11px", "border": "1px solid #C4C4C4", "borderRadius": "5px" }} type="number" variant="outlined" label="" source="TotalAmount" validate={[required()]} rowsMax={2} value={item.TatIncentiveAmount} onChange={(event) => handleChange(event, index, "TatIncentiveAmount")} source={"Col5" + index} disabled/>
                  </TableCell>
                  <TableCell>
                    <input style={{ width: "100px", textAlign: "right", height: "35px", fontSize: "11px", "border": "1px solid #C4C4C4", "borderRadius": "5px" }} type="number" variant="outlined" label="" source="TotalAmount" validate={[required()]} rowsMax={2} value={item.DeductionAmount } onChange={(event) => handleChange(event, index, "DeductionAmount")} source={"Col5" + index} disabled />
                  </TableCell>
                  
                  <TableCell>

                    {(item.invTypeId === 50 || item.invTypeId === '50' || item.invTypeId === '43' || item.invTypeId === '43' || item.invTypeId === 43 || item.invTypeId === 43 || item.invTypeId === '44' || item.invTypeId === '44' || item.invTypeId === 44 || item.invTypeId === 44) ? <input style={{ width: "100px", textAlign: "right", height: "35px", fontSize: "11px", "border": "1px solid #C4C4C4", "borderRadius": "5px" }} type="number" label="" source="taxPercentage" validate={[required()]} rowsMax={2} value={item.TotalAmount} disabled source={"Col5" + index} />
                      : <input style={{ width: "100px", textAlign: "right", height: "35px", fontSize: "11px", "border": "1px solid #C4C4C4", "borderRadius": "5px" }} type="number" variant="outlined" label="" source="TotalAmount" validate={[required()]} rowsMax={2} value={item.TotalServiceAmount} disabled onChange={(event) => handleChange(event, index, "TotalServiceAmount")} source={"Col5" + index}
                      disabled

                      />
                    }
                    {" "} {index != 0 ? <button type="button" onClick={() => handleRemove(index)} >-</button> : null} {"  "}
                    {(invoiceData.length - 1) === index ? <button button type="button" onClick={() => handleAdd(index)}>+</button> : null}
                  </TableCell>
                  <TableCell>
                    {item.FinalAmount}

                  </TableCell>

                </tr>
              })}
            </TableBody>
          </Table>
        }
        {toId > 0 && <FormDataConsumer  >
          {formDataProps => (
            <div style={{ display: "block", "text-align": "-webkit-right" }}>
              <div>
                <table style={{ width: 300 }} >
                  <tbody>
                    <tr>
                      <td style={{ fontWeight: "bold", fontSize: "11px" }} >Invoice Amount :</td>
                      <td style={{ textAlign: "right", fontSize: "11px" }} >{invoiceAmount}</td>
                    </tr>
                    <tr>
                      <td style={{ fontWeight: "bold", fontSize: "11px" }} >Total Tax Amount :</td>
                      <td style={{ textAlign: "right", fontSize: "11px" }} >{invoiceTaxAmount}</td>
                    </tr>
                    <tr>
                      <td style={{ fontWeight: "bold", fontSize: "11px" }} >Round-Off Amount :</td>
                      <td style={{ textAlign: "right", fontSize: "11px" }} >{invoiceRoundOfAmount}</td>
                    </tr>
                    <tr>
                      <td style={{ fontWeight: "bold", fontSize: "11px" }} >Grand Total :</td>
                      <td style={{ textAlign: "right", fontSize: "11px" }} >{invoiceGrandTotal}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          )}
        </FormDataConsumer>
        }
        <FormDataConsumer >
          {({ formData, ...rest }) => {

            setToId(formData.toId);

            if (formData.toId != toId) { loadtodata(formData.toId) }
          }

          }
        </FormDataConsumer>
        <FormDataConsumer >
          {({ formData, ...rest }) => {
            if (firstLoad) {

              loadinvoiceData(formData.id)
              setfirstLoad(false);
              setInvoiceId(formData.id)
              setClaimOrdId(formData.claimOrdId)
              setinvoiceAmount(formData.totalInvoiceAmount.toFixed(2))
              setInvoiceRoundOfAmount(formData.roundOffAmount.toFixed(2))
              setToTaxPer(formData.taxPercentage.toFixed(2))
              setTaxTypeId(formData.taxTypeId)
              setInvoiceGrandTotal(formData.grossInvoiceAmount.toFixed(2))
              setInvoiceTaxAmount(formData.totalTaxAmount.toFixed(2))
              setToTypeId(formData.toTypeId);

              if(parseInt(formData.toTypeId)==1)
              {
                
                setIsTpaInvoice(true);
              }

            }
          }
          }
        </FormDataConsumer>
      </SimpleForm>
      
    </Edit>
     <Drawer
    anchor="right"
    onClose={closeEdit}
    classes={{ paper: myclasses.drawerPaper, }}
    open={isPrint || isPrintWithOutHeader }>
      { isPrint && <InvoicePrint
                      onClose={closeEdit}
                      onCancel={closeEdit}
                      invoiceId={invoiceId}
                      claimInvDetId={claimInvDetId}
                      isOpen={isPrint}
                      {...props} />}
      { isPrintWithOutHeader && <InvoicePrint2
                      onClose={closeEdit}
                      onCancel={closeEdit}
                      invoiceId={invoiceId}
                      isOpen={isPrint}
                      claimInvDetId={claimInvDetId}
                      {...props} />}
      </Drawer>
      </>
  );
};
