
import React from 'react';
import {
    List, Datagrid, TextField, DateField, NumberField, ReferenceField,
    BooleanField, EditButton, DeleteButton, ImageField, TextInput, Filter
} from 'react-admin';

const DisplayFileLink = (props) => {
    let { record } = props;

    if (record.docUrl && record.docUrl.length > 0)
        return (
            <a href={record.docUrl}>View File</a>
        )
    else {
        return null
    }
}

export const InsurerCircularDocsList = props => (
    <List {...props}
        perPage={100}
        hasCreate={false}
        exporter={false}
        title=" - Insurer Circular List"
        resource="insurer-circular-docs"
        basePath="insurer-circular-docs"
        bulkActionButtons={false}
        filters={false}
        filterDefaultValues={{
            insurerId: props.insurerId
        }}
    >
        <Datagrid rowClick={false}>
            <DateField label="Valid From" source="validFrom" />
            <DateField label="Valid To" source="validTo" />
            <TextField label="Remarks" source="remarks" />
            <DisplayFileLink label="Document URL" source="docUrl" />
        </Datagrid>
    </List>
);