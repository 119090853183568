import React, { useState, useCallback, useEffect } from 'react';
import * as apiClient from "../../common/apiClient";
import { useForm } from 'react-final-form';

export const InsurerAddress = ({ formData, onCircular, onTPACircular, ...rest }) => {
    const form = useForm();
    const [insurer, setInsurer] = useState(null);
    console.log(formData, "=====formData==============");
    useEffect(() => {
        if (!formData.insurerId) return;
        apiClient.getInsurerData(formData.insurerId).then(res => {
            setInsurer(res);
            console.log(res);
        }).catch(err => {
            console.log(err);
        });
    }, []);
    return (
        <div className="insurer-address-field-parent">
            {insurer && <div>
                {insurer.add1} {insurer.add2} {insurer.add3} {insurer.city}
            </div>}
            <div style={{display:'flex', justifyContent:'center'}}>
                {formData.insurerId && <div className='circular-button' onClick={() => {
                    onCircular(formData.insurerId);
                }}>Insurer Circulars</div>}
                {formData.tpaid != 0 && <div className='circular-button' onClick={() => {
                    onTPACircular(formData.tpaid);
                }}>TPA Circulars</div>}
            </div>
        </div>
    )
}
