import React, { useState, useEffect } from "react";
import {
  Create,
  FormDataConsumer,
  SelectInput,
  TextInput,
  BooleanInput,
  required,
  ReferenceInput,
  NumberInput,
  ImageField,
  SaveButton,
  Toolbar,
  Button,
  SimpleForm,
  FormTab,
  useNotify,
  AutocompleteInput,
  DateInput,
  useRedirect
} from "react-admin";
import { useStyles } from "../../formStyles";
import { CreateToolbar } from "../../CreateToolbar";
import moment from "moment";
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TextareaAutosize from '@material-ui/core/TextareaAutosize';
import * as apiClient from "../../common/apiClient";
import CancelIcon from '@material-ui/icons/Cancel';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  useParams,
  useHistory
} from "react-router-dom";
export const InvoiceCreate = (props) => {
  const notify = useNotify();
  const redirect = useRedirect();
  const classes = useStyles({});
  const [invoiceData, setInvoiceData] = useState([])
  const [toTaxPer, setToTaxPer] = useState(0)
  const [isTpaInvoice, setIsTpaInvoice] = useState(false)
  const [toId, setToId] = useState(0)
  const [claimOrdId, setClaimOrdId] = useState(0)
  const [toTaxTypeId, setTaxTypeId] = useState(0)
  const [invoiceAmount, setinvoiceAmount] = useState(0)
  const [invoiceTaxAmount, setInvoiceTaxAmount] = useState(0)
  const [invoiceRoundOfAmount, setInvoiceRoundOfAmount] = useState(0)
  const [invoiceGrandTotal, setInvoiceGrandTotal] = useState(0)
  const [fromDate, setFromDate] = useState(null)
  const [toDate, setToDate] = useState(null)

  let history = useHistory();
  const [toTypeId, setToTypeId] = useState(0)
  const [invTypeData, setinvTypeData] = useState([{ id: '43', name: 'CLAIM' },
  // { id: '44', name: 'RECLAIM' }, 
  { id: '45', name: 'Tie-Up' },
  { id: '46', name: 'Desk Management' },
  { id: '47', name: 'FreeHand' },
  { id: '50', name: 'Remarks NP' }])

  useEffect(() => {
    //setInvoiceData([{toId:toId,invTypeId:0,claimOrdInvId:1,claimOrdStartDate:"",claimOrdEndDate:"",Particulars:"",TotalAmount:0,TotalServicePerc:0,TotalServiceAmount:0}])
  }, []);

  const loadtodata = (id) => {

    if (id === undefined) {
      return;
    }
    let istpa = 0;
    if (isTpaInvoice) {
      istpa = 1;
    }
    setToTaxPer(0)
    setTaxTypeId(0)
    apiClient.get_tpa_insurer_data(id, istpa).then(res => {
      debugger
      if (res.length) {
        setToTaxPer(res[0].taxPercentage)
        setTaxTypeId(res[0].TaxtTypeID)
      }
    }).catch(err => {
      console.log(err);
    });
  }

  const loadInvTypeCalculation = (claimOrdId, toId, invTypeId, fromdate, todate, index) => {

    let data = [...invoiceData]
    // data[index].TotalServiceAmount=0;
    // data[index].TotalServicePerc=0;
    // data[index].TotalAmount=0;
    // data[index].Particulars="";
    setInvoiceData([...data])
    let typeId = 0;
    if (isTpaInvoice) {
      typeId = 1;
    }
    apiClient.getCalimOrd_BillingPerc_ByDateRange(claimOrdId, toId, typeId, fromdate, todate).then(res => {
      if (res.length === 0) { notify("No claim found", "warning") }
      else {
        let data = [...res]

        // data[index].TotalServiceAmount=res[0].TotalServiceAmount;
        // data[index].TotalServicePerc=res[0].TotalServicePerc;
        // data[index].TotalAmount=1000;//res[0].TotalAmount;
        // data[index].Particulars=res[0].Particulars;
        setInvoiceData([...data])
        calulateTotal([...data]);
      }


    }).catch(err => {
      alert(err)
      console.log(err);
    });
  }
  const handleRemove = async (index) => {
    let data = [...invoiceData]
    data.splice(index, 1);
    setInvoiceData([...data])
    calulateTotal([...data])
  }

  const handleAdd = async (index) => {
    let data = [...invoiceData]
    data.push({ toId: toId, claimOrdInvId: 1, invTypeId: 0, claimOrdStartDate: "", claimOrdEndDate: "", Particulars: "", TotalAmount: 0, TotalServicePerc: 0, TotalServiceAmount: 0 })
    setInvoiceData([...data])
  }

  const handleChange = async (event, index, identifier) => {
    if (identifier == "hospital") {
      alert(event.target.value);
      return
    }
    let data = [...invoiceData]
    data[index][identifier] = event.target.value;
    if (identifier === 'invTypeId') {
      data[index]["TotalAmount"] = 0;
      data[index]["claimOrdStartDate"] = "";
      data[index]["claimOrdEndDate"] = "";
    }
    setInvoiceData([...data])
    if ((identifier === 'invTypeId')) {
      let payload = {}
      payload.fromdate = data[index].claimOrdStartDate
      payload.todate = data[index].claimOrdEndDate
      if (identifier === 'claimOrdStartDate') {
        payload.fromdate = event.target.value
      }
      if (identifier === 'claimOrdEndDate') {
        payload.todate = event.target.value
      }

      let filterinvTypeData = invTypeData.filter(x => x.id === data[index].invTypeId);
      debugger
      if (filterinvTypeData.length && (filterinvTypeData[0].name === 'CLAIM' || filterinvTypeData[0].name === 'RECLAIM')) {

        payload.invTypeId = filterinvTypeData[0].name;
        //if(payload.fromdate.length &&  payload.todate.length )
        loadInvTypeCalculation(claimOrdId, toId, payload.invTypeId, payload.fromdate, payload.todate, index);
      }


    }
    calulateTotal([...data]);

  }
  const handleSubmit = async (formdata) => {
    console.log(formdata);
    let user = localStorage.getItem("userId");
    if (user) {
      user = parseInt(user);
    }

    if (formdata.billYearFrom > formdata.billYearTo) {
      notify("Please Valid Bill Year From", "warning")
      return;
    }
    let IsValid = true;
    let errormessage = "";
    for (let index = 0; index < invoiceData.length; index++) {

      if (invoiceData[index].invTypeId <= 0) {
        errormessage = `Please Select Invoice Type at row no ${index + 1}`
        IsValid = false;
        break
      }

      if (invoiceData[index].Particulars === "") {
        errormessage = `Please Enter Particulars at row no ${index + 1}`
        IsValid = false;
        break
      }
      if (invoiceData[index].invTypeId != '47' || invoiceData[index].invTypeId != 47) {
        if (invoiceData[index].TotalAmount < 0) {
          errormessage = `Please Enter Amount  at row no ${index + 1}`
          IsValid = false;
          break
        }
      }

    }
    if (!IsValid) {
      notify(errormessage, "warning")
      return;
    }

    debugger
    let data = {};
    data.invoiceId = 0;
    data.toId = toId;
    data.claimOrdId = claimOrdId;
    data.taxtypeId = toTaxTypeId;
    data.claimOrdId = claimOrdId;
    data.totypeId = isTpaInvoice === true ? 1 : 0;
    data.taxPer = toTaxPer;
    data.billYearFrom = formdata.billYearFrom;
    data.billYearTo = formdata.billYearTo;
    data.billMonth = formdata.billMonthId;
    data.invoiceDate = formdata.invoiceDate;
    data.invoiceAmount = invoiceAmount;
    data.taxAmount = invoiceTaxAmount;
    data.roundOffAmount = invoiceRoundOfAmount;
    data.totalAmount = invoiceGrandTotal;
    data.isInvoiceSubmitted=0;
    data.invoiceSubDate='';
    data.fromDate = fromDate;
    data.toDate = toDate;
    data.userId = user;
    data.invoiceDetail = [...invoiceData];
    data.paymentrcvd = 0;
    data.paymentrcvddt = "";
    data.NeftDetails = "";
    data.fromDate = fromDate;
    data.toDate = toDate;


    apiClient.addupdateClaimInvoice(data).then(res => {
      if (res[0].result) {
        notify(res[0].message)
        redirect("/claim-invs");
      }
      else {
        notify(res[0].message)
      }


    }).catch(err => {
      notify("unable to add Invoice", "warning")
      console.log(err);
    });
  }

  const handleLoadData = async (formdata) => {
    debugger
    console.log(fromDate);
    console.log(toDate);
    let user = localStorage.getItem("userId");
    if (user) {
      user = parseInt(user);
    }

    if (formdata.billYearFrom > formdata.billYearTo) {
      notify("Please Valid Bill Year From", "warning")
      return;
    }
    let typeId = 0;
    if (isTpaInvoice) {
      typeId = 1;
    }
    loadInvTypeCalculation(claimOrdId, toId, typeId, fromDate, toDate, 0);

  }

  const calulateTotal = async (invData) => {
    debugger
    let data = [...invData]
    let _invData = [];
    let invoiceAmount = 0
    let finalinvoiceAmount = 0
    let invoiceTaxAmount = 0
    let invoiceRoundOff = 0
    let invoiceFinalAmount = 0
    data.forEach((Element) => {
      invoiceAmount = parseFloat(invoiceAmount) + parseFloat(Element.TotalServiceAmount) + parseFloat(Element.TatIncentiveAmount) + parseFloat(Element.DocumentAmount) + parseFloat(Element.ExtraConveyance)
      finalinvoiceAmount = 0;
      finalinvoiceAmount = parseFloat(Element.TotalServiceAmount) + parseFloat(Element.TatIncentiveAmount) + parseFloat(Element.DocumentAmount) + parseFloat(Element.ExtraConveyance)
      Element.FinalAmount = finalinvoiceAmount.toFixed(2);
      _invData.push(Element);
    })
    invoiceTaxAmount = (invoiceAmount * toTaxPer * .01)
    invoiceRoundOff = Math.round(invoiceAmount + invoiceTaxAmount) - (invoiceAmount + invoiceTaxAmount);

    invoiceFinalAmount = parseFloat(invoiceAmount) + parseFloat(invoiceTaxAmount) + parseFloat(invoiceRoundOff);
    setinvoiceAmount(invoiceAmount.toFixed(2));
    setInvoiceTaxAmount(invoiceTaxAmount.toFixed(2));
    setInvoiceRoundOfAmount(invoiceRoundOff.toFixed(2))
    setInvoiceGrandTotal(invoiceFinalAmount.toFixed(2));
    setInvoiceData(_invData);

  }
  const PostCreateToolbar = props => (

    <Toolbar {...props}>
      {props.showSave ? <SaveButton submitOnEnter={true} onSave={props.onSave} label={"Save"} /> : null}
      {<Button
        variant="contained" color="primary"
        label={"Fetch Data"} size="medium"
        startIcon={<CancelIcon />}
        style={{ marginLeft: "30px" }}
        onClick={props.loadData}
      />}
      <Button
        variant="contained" color="primary"
        label="Back" size="medium"
        startIcon={<CancelIcon />}
        style={{ marginLeft: "30px" }}
        onClick={() => history.push("/claim-invs")}
      />
    </Toolbar>
  );
  return (
    <Create title="Create Invoice " {...props}>
      <SimpleForm variant="outlined" redirect="list"
        toolbar={<PostCreateToolbar onSave={handleSubmit}
          title={invoiceData.length > 0 ? "Save" : "Save"}
          showSave={invoiceData.length > 0 ? true : false}
          loadData={handleLoadData}
          onCancel={props.onCancel}  {...props} />}>
        <BooleanInput source="isTpa" label="Is TPA Invoice" inititalVale={false} onChange={(val) => setIsTpaInvoice(val)} />
        {isTpaInvoice && <ReferenceInput perPage={250} sort={{ field: 'name', order: 'ASC' }} validate={[required()]}
          filterToQuery={searchText => ({ 'name~like': searchText })}
          label="TPA Name" source="toId" reference="tpas" fullWidth={true} formClassName={classes.one_4_input} inititalVale={toId}
          onChange={(event) => { setToId(event); loadtodata(event) }}
        >
          <AutocompleteInput optionText="name" shouldRenderSuggestions={(val) => { return val && val.trim().length >= 1 }} />
        </ReferenceInput>
        }
        {!isTpaInvoice && <ReferenceInput perPage={250} sort={{ field: 'name', order: 'ASC' }} validate={[required()]}
          filterToQuery={searchText => ({ 'name~like': searchText })}
          label="Insurer Name" source="event" reference="insurers" fullWidth={true} formClassName={classes.one_4_input} inititalVale={toId}
          onChange={(event) => { setToId(event); loadtodata(event) }}
        >
          <AutocompleteInput optionText="name" shouldRenderSuggestions={(val) => { return val && val.trim().length >= 1 }} />
        </ReferenceInput>
        }

        <ReferenceInput label="Tax Type" perPage={5000} source="taxtTypeID" fullWidth={true} filter={{ type: 'TT' }} reference="lookups" formClassName={classes.two_4_input} disabled  >
          <SelectInput optionText="name" disabled value={toTaxTypeId} />
        </ReferenceInput>

        <NumberInput label="Tax Percentage" source="taxPercentage" fullWidth={true} formClassName={classes.three_4_input} disabled value={toTaxPer} />
        <SelectInput source="billYearFrom" label="Bill Year Form" validate={[required()]} fullWidth={true} formClassName={classes.last_4_input} choices={[{ id: '2020', name: '2020' }, { id: '2021', name: '2021' }, { id: '2022', name: '2022' }, { id: '2023', name: '2023' }, { id: '2024', name: '2024' }, { id: '2025', name: '2025' }, { id: '2026', name: '2026' }, { id: '2027', name: '2027' }, { id: '2028', name: '2028' }, { id: '2029', name: '2029' }, { id: '2030', name: '2030' }]} />
        <SelectInput source="billYearTo" label="Bill Year To" validate={[required()]} fullWidth={true} formClassName={classes.one_4_input} choices={[{ id: '2020', name: '2020' }, { id: '2021', name: '2021' }, { id: '2022', name: '2022' }, { id: '2023', name: '2023' }, { id: '2024', name: '2024' }, { id: '2025', name: '2025' }, { id: '2026', name: '2026' }, { id: '2027', name: '2027' }, { id: '2028', name: '2028' }, { id: '2029', name: '2029' }, { id: '2030', name: '2030' }]} />

        <SelectInput source="billMonthId" label="Bill Month" validate={[required()]} fullWidth={true} formClassName={classes.two_4_input} choices={[{ id: '1', name: 'Jan' }, { id: '2', name: 'Feb' }, { id: '3', name: 'March' }, { id: '4', name: 'April' }, { id: '5', name: 'May' }, { id: '6', name: 'June' }, { id: '7', name: 'July' }, { id: '8', name: 'August' }, { id: '9', name: 'Sept' }, { id: '10', name: 'Oct' }, { id: '11', name: 'Nov' }, { id: '12', name: 'Dec' }]} />
        <DateInput validate={[required()]} label="Invoice Date" source="invoiceDate" fullWidth={true} formClassName={classes.three_4_input} />
        <div style={{ width: "40px" }} ></div>
        <DateInput validate={[required()]} label="From Date" source="fromDate" fullWidth={true} formClassName={classes.one_4_input} onChange={(event) => { setFromDate(event.target.value) }} />
        <DateInput validate={[required()]} label="To Date" source="toDate" fullWidth={true} formClassName={classes.two_4_input} onChange={(event) => setToDate(event.target.value)} />

        {
          toId > 0 && invoiceData.length > 0 &&
          <Table className={classes.customtable} size="small" aria-label="a dense table">
            <TableHead>
              <TableRow>
                {/* <TableCell >Invoice Type</TableCell> */}
                {/* <TableCell>Date From</TableCell>
                                <TableCell>Date To</TableCell> */}

                <TableCell>VID No</TableCell>
                <TableCell>Particulars</TableCell>
                <TableCell>Extra Conveyance</TableCell>
                <TableCell>Document Fee</TableCell>
                <TableCell>Incentive Fee</TableCell>
                <TableCell>Ded.Amt</TableCell>
                <TableCell>Prof Fee</TableCell>  
                <TableCell>Total Amount</TableCell>
              </TableRow>
            </TableHead>
            <TableBody >
              {invoiceData.map((item, index) => {
                return <tr >
                  {/* <TableCell> 
                                        <select  style={{height:"35px",fontSize:"11px","border": "1px solid #C4C4C4","borderRadius": "5px"}} value={item.invTypeId}  onChange={(event)=>handleChange(event,index,"invTypeId")}   variant="outlined" source={"Col1"+index} label="Invoice Type"  fullWidth={true} formClassName={classes.one_4_input}  >
                                        <option   value={'0'}>Select </option>
                                        {invTypeData.map((item)=>{ 
                                          return <option  selected={item.id===item.invTypeId} value={item.id}>{item.name}</option>
                                        })} 
                                          </select>
                                      </TableCell> */}
                  {/* <TableCell>
                                      {(item.invTypeId!='50' ||  item.invTypeId!=50)? <input type="date"   style={{width:"122px",height:"35px",fontSize:"11px","border": "1px solid #C4C4C4","borderRadius": "5px"}}  value={item.claimOrdStartDate}  onChange={(event)=>handleChange(event,index,"claimOrdStartDate")} variant="outlined" validate={[required()]} label="Invoice Date" source={"Col2"+index} fullWidth={true} formClassName={classes.two_4_input} onKeyDown={(e) => e.preventDefault()} />:""}
                                      </TableCell>
                                      <TableCell>
                                      {(item.invTypeId!='50' ||  item.invTypeId!=50)?<input type="date"    style={{width:"122px",height:"35px",fontSize:"11px","border": "1px solid #C4C4C4","borderRadius": "5px"}}  value={item.claimOrdEndDate}  onChange={(event)=>handleChange(event,index,"claimOrdEndDate")} formClassName={classes.customInput} validate={[required()]} label="Invoice Date" source={"Col3"+index} fullWidth={true} formClassName={classes.two_4_input} onKeyDown={(e) => e.preventDefault()}/>:""}
                                      </TableCell> */}
                  <TableCell>
                    {item.ClaimNo}

                  </TableCell>
                  <TableCell>
                    {/* <TextareaAutosize variant="outlined" rowsMax={2} value={item.Particulars}  onChange={(event)=>handleChange(event,index,"Particulars")}    aria-label="maximum height" placeholder="Particulars" defaultValue=""  source={"Col4"+index}/> */}
                    <textarea style={{ height: "100px", fontSize: "11px", "border": "1px solid #C4C4C4", "borderRadius": "5px" }} value={item.Particulars} onChange={(event) => handleChange(event, index, "Particulars")} aria-label="maximum height" placeholder="Particulars" defaultValue="" source={"Col4" + index} />
                  </TableCell>
                  <TableCell>
                    <input style={{ width: "100px", textAlign: "right", height: "35px", fontSize: "11px", "border": "1px solid #C4C4C4", "borderRadius": "5px" }} type="number" variant="outlined" label="" source="TotalAmount" validate={[required()]} rowsMax={2} value={item.ExtraConveyance} onChange={(event) => handleChange(event, index, "ExtraConveyance")} source={"Col5" + index} disabled />
                  </TableCell>
                  <TableCell>
                    <input style={{ width: "100px", textAlign: "right", height: "35px", fontSize: "11px", "border": "1px solid #C4C4C4", "borderRadius": "5px" }} type="number" variant="outlined" label="" source="TotalAmount" validate={[required()]} rowsMax={2} value={item.DocumentAmount} onChange={(event) => handleChange(event, index, "DocumentAmount")} source={"Col5" + index} disabled />
                  </TableCell>
                  <TableCell>
                    <input style={{ width: "100px", textAlign: "right", height: "35px", fontSize: "11px", "border": "1px solid #C4C4C4", "borderRadius": "5px" }} type="number" variant="outlined" label="" source="TotalAmount" validate={[required()]} rowsMax={2} value={item.TatIncentiveAmount} onChange={(event) => handleChange(event, index, "TatIncentiveAmount")} source={"Col5" + index} disabled />
                  </TableCell>
                  <TableCell>
                    <input style={{ width: "100px", textAlign: "right", height: "35px", fontSize: "11px", "border": "1px solid #C4C4C4", "borderRadius": "5px" }} type="number" variant="outlined" label="" source="TotalAmount" validate={[required()]} rowsMax={2} value={item.DeductionAmount} onChange={(event) => handleChange(event, index, "DeductionAmount")} source={"Col5" + index} disabled />
                  </TableCell>
                  
                  <TableCell>

                    {(item.invTypeId === 50 || item.invTypeId === '50' || item.invTypeId === '43' || item.invTypeId === '43' || item.invTypeId === 43 || item.invTypeId === 43 || item.invTypeId === '44' || item.invTypeId === '44' || item.invTypeId === 44 || item.invTypeId === 44) ? <input style={{ width: "100px", textAlign: "right", height: "35px", fontSize: "11px", "border": "1px solid #C4C4C4", "borderRadius": "5px" }} type="number" label="" source="taxPercentage" validate={[required()]} rowsMax={2} value={item.TotalAmount} disabled source={"Col5" + index} />
                      : <input style={{ width: "100px", textAlign: "right", height: "35px", fontSize: "11px", "border": "1px solid #C4C4C4", "borderRadius": "5px" }} type="number" variant="outlined" label="" source="TotalAmount" validate={[required()]} rowsMax={2} value={item.TotalServiceAmount} disabled onChange={(event) => handleChange(event, index, "TotalServiceAmount")} source={"Col5" + index}

                      />
                    }
                    {" "} {index != 0 ? <button type="button" onClick={() => handleRemove(index)} >-</button> : null} {"  "}
                    {(invoiceData.length - 1) === index ? <button button type="button" onClick={() => handleAdd(index)}>+</button> : null}
                  </TableCell>
                  <TableCell>
                    {item.FinalAmount}

                  </TableCell>

                </tr>
              })}
            </TableBody>
          </Table>
        }

        {toId > 0 && <FormDataConsumer  >
          {formDataProps => (
            <div style={{ display: "block", "text-align": "-webkit-right" }}>
              <div>
                <table style={{ width: 300 }} >
                  <tbody>
                    <tr>
                      <td style={{ fontWeight: "bold", fontSize: "11px" }} >Invoice Amount :</td>
                      <td style={{ textAlign: "right", fontSize: "11px" }} >{invoiceAmount}</td>
                    </tr>
                    <tr>
                      <td style={{ fontWeight: "bold", fontSize: "11px" }} >Total Tax Amount :</td>
                      <td style={{ textAlign: "right", fontSize: "11px" }} >{invoiceTaxAmount}</td>
                    </tr>
                    <tr>
                      <td style={{ fontWeight: "bold", fontSize: "11px" }} >Round-Off Amount :</td>
                      <td style={{ textAlign: "right", fontSize: "11px" }} >{invoiceRoundOfAmount}</td>
                    </tr>
                    <tr>
                      <td style={{ fontWeight: "bold", fontSize: "11px" }} >Grand Total :</td>
                      <td style={{ textAlign: "right", fontSize: "11px" }} >{invoiceGrandTotal}</td>
                    </tr>
                  </tbody>
                </table>
              </div>

            </div>
          )}
        </FormDataConsumer>
        }
        <FormDataConsumer >
          {({ formData, ...rest }) => {


            if (formData.toId != toId && toId > 0) {

              //setToId(formData.toId) ;  
              // loadtodata(toId)
            }
          }
          }
        </FormDataConsumer>
      </SimpleForm>

    </Create>
  );
};
