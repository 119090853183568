import React, { useState, useCallback } from 'react';
import Button from '@material-ui/core/Button'
import InvoiceUploadDetails from './InvoiceUploadDetails';

import {
  List,
  Datagrid,
  TextField,
  DateField,
  ReferenceField,
  NumberField,
  BooleanField,
  EditButton,
  DeleteButton,
  ImageField,
  Filter,
  ReferenceInput,
  SearchInput,
  SelectInput,
  AutocompleteInput, TopToolbar,CreateButton, ExportButton,
} from "react-admin";
import { makeStyles } from '@material-ui/core/styles';
import { Drawer } from '@material-ui/core';
import InvoicePrint from "./InvoicePrint/InvoicePrint";
import InvoicePrint2 from "./InvoicePrint2/InvoicePrint";
import * as apiClient from "../../common/apiClient";
const myStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
  },
  list: {
    flexGrow: 1,
    transition: theme.transitions.create(['all'], {
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginRight: 0,
  },
  listWithDrawer: {
    marginRight: 300,
  },
  drawerPaper: {
    zIndex: 100,
    marginTop: 50,
    marginRight: 5,

    width: 650,
  },
}));
const Filters = props => (
  <Filter {...props} variant="outlined">
    <SearchInput variant="outlined" label="Invoice No" source="invoiceNo~like" alwaysOn />
    <ReferenceInput perPage={25000} sort={{ field: 'name', order: 'ASC' }} filterToQuery={searchText => ({ 'name~like': searchText })}
      label="Tpa Name" source="hospitalId" reference="tpas" fullWidth={true} allowEmpty alwaysOn>
      <AutocompleteInput optionText="name" shouldRenderSuggestions={(val) => { return val && val.trim().length >= 1 }} />
    </ReferenceInput>
    {/* <ReferenceInput perPage={25000} sort={{ field: 'name', order: 'ASC' }}  filterToQuery={searchText => ({ 'name~like': searchText })}
          label="Insurer Name" source="hospitalId" reference="Insurers" fullWidth={true}  allowEmpty alwaysOn>
          <AutocompleteInput optionText="name" shouldRenderSuggestions={(val) => { return val && val.trim().length >= 1 }} />
    </ReferenceInput> */}
  </Filter>
);

const ListActions = (props) => (
  <TopToolbar>
    {props.hasCreate && <CreateButton {...props} label="Create Invoice" />}
    {props.hasExport && <ExportButton {...props} />}
    {props.hasEdit && <Button onClick={props.onUploadPayment} {...props} >Upload Invoice</Button>}
  </TopToolbar>
);

export const InvoiceList = (props) => {
  const [isPrint, setIsPrint] = useState(false);
  const [isPrintWithOutHeader, setIsPrintWithOutHeader] = useState(false);
  const [invoiceId, setinvoiceId] = useState(0);

  const [isDisplayPayments, setIsDisplayPayments] = useState(false);

  const onUploadPayment = () => {
    setIsDisplayPayments(true);
  }


  const myclasses = myStyles({});
  let propsObj = { ...props };
  if (propsObj.permissions) {

    let resourcename = `/${props.resource}`;
    let myPermissions = propsObj.permissions.filter(item => item.NavigateUrl.toLowerCase().trim() == resourcename);
    if (myPermissions.length > 0) {
      let p = myPermissions[0];
      propsObj.hasList = (p.View == 1) ? true : false;
      propsObj.hasShow = (p.View == 1) ? true : false;
      propsObj.hasEdit = (p.Edit == 1) ? true : false;
      propsObj.hasDelete = (p.Delete == 1) ? true : false;
      propsObj.hasCreate = (p.Create == 1) ? true : false;
      propsObj.hasExport = (p.Export == 1) ? true : false;
    }
  }
  const closeEdit = () => {
    setIsPrint(false)
    setIsPrintWithOutHeader(false);
    setIsDisplayPayments(false);
  }

  const PrintButton = (props) => (
    <Button onClick={props.click}  > Print With Header </Button>
  )
  const PrintButtonWithoutHeader = (props) => (
    <Button onClick={props.click}  > Print  </Button>
  )
  const onCancel = () => {
    setIsPrint(false);
    setIsDisplayPayments(false);
  }
  const handleClose = useCallback(() => {
    //setIsAddTpa(false);
  }, []);
  function showPopup(id) {

    setinvoiceId(id)
    setIsPrint(true);
    setIsPrintWithOutHeader(false);
  }
  function showPopupWithoutHeader(id) {

    setinvoiceId(id)
    setIsPrint(false);
    setIsPrintWithOutHeader(true);
  }

  const DisplayFileLink = (props) => {
    let { record } = props;

    return (
      <PrintButton click={(e) => showPopup(record.id)} />

    )
  }
  const DisplayFileLink1 = (props) => {
    let { record } = props;

    return (
      <PrintButtonWithoutHeader click={(e) => showPopupWithoutHeader(record.id)} />

    )
  }
  const DisplayFileName = (props) => {
    let { record } = props;
    return LoadPartyName();
  }

  const LoadPartyName = async (id, type) => {

    await apiClient.loaddata('tpas/1').then(res => {

      return res.name;


    }).catch(err => {

    });

  }
  const DisplayName = (props) => {
    let { record } = props;
    console.log(record, "****record****")

    return (
      <ReferenceField source="toId" reference="insurers" label="Name"
        filter={{ id: record.toId }}
        link={false}>
        <TextField source="name" />
      </ReferenceField>

    )
  }

  return (

    <React.Fragment>
      <List resource={"claim-invs"} {...propsObj} exporter={propsObj.hasExport} bulkActionButtons={false}
        actions={<ListActions {...props} onUploadPayment={onUploadPayment} />}
        title="Invoice List" filters={<Filters />}  >
        <Datagrid >
          {propsObj.hasEdit && <EditButton />}
          <DisplayFileLink label="" source="id" />
          <DisplayFileLink1 label="" source="id" />
          <TextField source="invoiceNo" label="Invoice No" />
          <DateField source="invoiceDate" label="Invoice Date" locales="fr-FR" />

          {/* <DisplayName label="" source="toId" /> */}
          <ReferenceField source="createdBy" reference="users" label="Created By" link={false}>
            <TextField source="userName" />
          </ReferenceField>
          <DateField source="createdDate" label="Created Date" locales="fr-FR" />
          <ReferenceField source="modifyBy" reference="users" label="Updated By" link={false}>
            <TextField source="userName" />
          </ReferenceField>
          <DateField source="modifydate" label="Updated Date" locales="fr-FR" />
        </Datagrid>
      </List>
      <Drawer
        anchor="right"
        onClose={handleClose}
        classes={{ paper: myclasses.drawerPaper, }}
        open={isPrint || isPrintWithOutHeader || isDisplayPayments}>
        {isPrint && <InvoicePrint
          onClose={closeEdit}
          onCancel={onCancel}
          invoiceId={invoiceId}
          isOpen={isPrint}
          {...props} />}
        {isPrintWithOutHeader && <InvoicePrint2
          onClose={closeEdit}
          onCancel={onCancel}
          invoiceId={invoiceId}
          isOpen={isPrint}
          {...props} />}
        {isDisplayPayments && <InvoiceUploadDetails
          onClose={onCancel}
          onCancel={onCancel}
          invoiceId={invoiceId}
          {...props} />}
      </Drawer>
    </React.Fragment>
  );
};
