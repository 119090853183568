import React from 'react';
import { DateField, ReferenceField, List, Datagrid, TextField, BooleanField, Filter, SearchInput, EditButton } from 'react-admin';

const MyFilters = props => (
    <Filter {...props} variant="outlined" >
        <SearchInput label="Name" source="name~like" alwaysOn />
        <SearchInput label="Email ID" source="email~like" alwaysOn />
        <SearchInput label="Mobile No" source="mobileNo~like" alwaysOn />

    </Filter>
);

export const EmployeeList = props => {
    let propsObj = { ...props };
    if (propsObj.permissions) {
        let resourcename = `/${props.resource}`;
        let myPermissions = propsObj.permissions.filter(item => item.NavigateUrl.toLowerCase().trim() == resourcename);
        if (myPermissions.length > 0) {
            let p = myPermissions[0];
            propsObj.hasList = (p.View == 1) ? true : false;
            propsObj.hasShow = (p.View == 1) ? true : false;
            propsObj.hasEdit = (p.Edit == 1) ? true : false;
            propsObj.hasDelete = (p.Delete == 1) ? true : false;
            propsObj.hasCreate = (p.Create == 1) ? true : false;
            propsObj.hasExport = (p.Export == 1) ? true : false;
        }
    }

    return (
        <React.Fragment>
            <List {...propsObj} exporter={propsObj.hasExport} bulkActionButtons={false} title="List of Employees"
                filters={<MyFilters />}>
                <Datagrid rowClick={propsObj.hasEdit ? 'edit' : false}>
                    {propsObj.hasEdit && <EditButton />}
                    <TextField source="code" label="Code" />
                    <TextField source="name" label="Employee Name" />
                    <ReferenceField source="userId" reference="users" label="User" link={false}>
                        <TextField source="userName" />
                    </ReferenceField>
                    <TextField source="email" label="Email Address" />
                    <TextField source="mobileNo" label="Mobile No" />
                    <BooleanField source="is_active" label="Is Active" />

                    <ReferenceField source="createdBy" reference="users" label="Created By" link={false}>
                        <TextField source="userName" />
                    </ReferenceField>
                    <DateField source="createdDate" label="Created Date" locales="fr-FR" />
                    <ReferenceField source="modifyBy" reference="users" label="Updated By" link={false}>
                        <TextField source="userName" />
                    </ReferenceField>
                    <DateField source="modifydate" label="Updated Date" locales="fr-FR" />
                </Datagrid>
            </List>
        </React.Fragment>
    );
}